* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --mainBackgroundColor: rgb(4, 18, 6);
  --mainTopGradient: linear-gradient(
    to bottom,
    rgb(24, 38, 26),
    rgb(04, 18, 06)
  );
  /* --mainBottomGradient: linear-gradient(in #18261a, #041206 25% 80%, #020903); */
}

.masterBackground {
  bottom: 0;
  height: 100%;
  width: 100%;
  /* background: -webkit-linear-gradient(in #18261a, #041206 25% 80%, #020903); */
  /* background-image: -webkit-radial-gradient(20% 50%, circle closest-corner, rgba(4, 18, 6, 1), rgba(2, 9, 3, 1)) */
  background-image: -webkit-linear-gradient(270deg, rgba(4, 18, 6, .965), rgba(2, 9, 3, .98))
}

html {
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  scroll-behavior: smooth;
  color: rgb(160, 160, 160);
  /* min-height: 600px; */
  justify-content: center;

  /* min-width: 750px; */
  /* width:100%; */
}

body {
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  background: var(--mainBottomGradient);
  /* background-color: var(--mainBottomGradient); */
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  /* height: 100vh; */
  margin: auto;
}

nav {
  width: 100%;
  max-width: unset;
  /* max-width: 1800px; */
  max-height: 50px;
  background-color: rgba(0, 0, 0, 0.39);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  /* max-width: 1600px; */
}

nav .title {
  /* font-size: 1.5rem; */
  text-decoration: none;
  color: rgb(160, 160, 160);
  margin: 1rem;
}

nav .title:not(.active):hover {
  text-decoration: underline;
  cursor: default;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #a0a0a0;
  padding: 0.5rem;
  margin: 0 0.5rem;
}

nav ul li a:not(.active):hover {
  text-decoration: underline;
  cursor: default;
}

.canvas {
  left: 0;
  width: 100%;
  height: 100vh;
  /* max-width: 1800px; */
  position: absolute;
  background-image: var(--mainTopGradient);
  z-index: -1;
  overflow: visible;
  /* max-width: 1600px; */
}

#bigCanvas {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 4500px;
  border: 1px solid red;
  overflow: hidden;
}

.pageBlock {
  /* display: block; */
}

.pages {
  width: 100%;
  min-height: 100vh;
  /* background-color: #041206; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* min-height: 600px;
  max-height: 900px; */
  /* padding-top: 51px; */
  /* overflow: hidden; */

  /* border: 1px solid red; */
}
.pagesMobile {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pagesMobileSpacing {
  margin: 5%;
}
.pagesMobileSpacingContact {
  width: 100%;
  /* margin: 5%; */
}

main {
  width: 100%;
  /* min-height: 590px;
  max-width: 1600px; */
}

#landing {
  /* background-image: linear-gradient(to bottom, rgb(24, 38, 26), rgb(04, 18, 06)); */
  /* background-color: red; */
  background-color: #ffffff00;
  justify-content: center;
  height: 100vh;
}
#landingMobile {
  /* background-image: linear-gradient(to bottom, rgb(24, 38, 26), rgb(04, 18, 06)); */
  /* background-color: red; */
  background-color: #ffffff00;
  justify-content: flex-start;
  height: 100vh;
}

#footer {
  min-height: 20vh;
  background-image: linear-gradient(to bottom, #041206, rgb(0, 0, 0));
}

.landingPageContentMobile {
  padding-top: 20px;
  margin: 20px;
}
.landingPageContent {
  width: 100%;
  height: 45%;
  align-content: center;
}

#welcome {
  font-size: 4rem;
}
#welcomeMobile {
  font-weight: 500;
  font-size: 4.5rem;
  justify-content: flex-start;
  text-align: left;
  display: flex;
  line-height: 1.1;
}
.mobileGreeting {
  font-size: large;
  padding-top: 10px;
  padding-left: 8px;
  text-align: left;
}

#downloadLabel {
  margin-top: 25%;
  padding-bottom: 0.5%;
}

.myButtons {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    justify-self: center;
    /* padding: 5%; */
    background-color: rgba(24, 38, 26, 0);
    border: 1px solid #707070;
    margin-top: 1%;
}

#downloadCVButtonText {
  /* padding: 5%; */
  color: rgb(160, 160, 160);
}

#downloadButton {
  transition: transform 0.1s;
}

#downloadButton:hover {
  color: rgb(160, 160, 160);
  background-color: #0D180F;
  outline: none;
  transform: scale(1.02);
}

.downloadButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: .75%; */
  /* height: 20%; */
}

.downloadCVButton {
  width: 75%;
  /* border: 1px solid #707070; */
  height: 60%;
  margin-right: 2.5%;
  margin-left:  2.5%;
}

.downloadCVButtonContent {
  width: 100%;
  height: 100%;
}

.downloadButtonsCenterer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100px;
  max-width: 300px;
  height: 90px;  
}

/* button:focus,
button:hover {
  color: rgb(160, 160, 160);
  background-color: rgb(04, 18, 06);
  outline: none;
} */

.expMF {
  display: flex;
  width: 78%;
  height: 80%;
}
.expMF_Mobile {
  display: flex;
  height: 80%;
}

.container {
  display: grid;

  grid-template-columns: 65% 35%;
  grid-template-rows: 100%;
}
.containerMobile {
  width: 100%;
}

.workStory {
  display: flex;
  /* flex-direction: column; */
  /* height: 100%; */
  align-content: flex-start;
  flex-wrap: wrap;
}
.workStoryMobile {
  display: flex;
  /* flex-direction: column; */
  /* height: 100%; */
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

#storyAdjust {
  margin: 5% 0% 5% 0%;
}

.MobileDividerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3% 0% 3% 0%;
  height: 10px;
}
.MobileDivider {
  width: 100%;
  border-bottom: 1px solid rgb(160, 160, 160);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788));
}

#workExpHeader {
  margin-bottom: 2%;
  font-size: 1.5rem;
  font-weight: bolder;
}

.workParagraphs {
  height: 100%;
  text-align: left;
  padding-right: 7%;
}
.workParagraphsMobile {
  text-align: center;
  margin:  10% 0% 0% 0%;
  /* border-bottom: 1px solid rgb(160, 160, 160); */
}

.storySkills {
  font-size: 0.75rem;
  flex-direction: column;
  display: flex;
  width: 75%;
  margin-top: 10%;
}
.storySkillsMobile {
  font-size: 0.75rem;
  flex-direction: column;
  display: flex;
}

.storySkillsTitleList {
  display: flex;
  border-bottom: 1px solid rgb(160, 160, 160);
  width: 100%;
  text-align: center;
  padding: 2%;
  padding-left: 0%;
}
.storySkillsTitleListMobile {
  display: flex;
  border-bottom: 1px solid rgb(160, 160, 160);
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 2%;
  padding-left: 0%;
}

#workExpSkillsMobile {
  width: 100%
}

.storySkillsTitleList:hover {
}

#storySkillsListWrapper {
  display: flex;
  justify-content: flex-start;
}

.storySkillTitleListEntryClickable {
  display: flex;
  /* margin-right: 2%; */
}

.storySkillTitleListEntry {
  cursor: pointer;
  transition: transform 0.5s;
  text-align: center;
}
.storySkillTitleListEntry:hover {
  /* transform: scale(1.05); */
  font-style: italic;
}

.storySkillTitleListEntryDivider {
  /* margin: 0% 2%; */
}

#space {
  width: 5px;
}

.storySkillsList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  flex-direction: column;
  margin-left: 2%;
  margin-top: 2%;
}

.storySkillListEntry {
  margin-bottom: 0.5%;
}
.storySkillListEntry:hover {
  cursor: default;
}

.storySkillsListContainer ul {
  padding-left: 2%;
  list-style-type: none;
}

.storySkillsListContainer ul li {
  text-indent: -8px;
}
.storySkillsListContainer ul li::before {
  content: "- ";
}

.workSpecificsWrapper {
  display: grid;
  width: 100%;
  flex-direction: column;
  /* justify-content: center; */
  /* justify-content: space-between; */
}

.workSpecifics {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.workplaceWrapper {
  /* height: 100%;
  width: 75%; */
  /* border: 1px solid orange; */
}
.workplaceWrapperMobile {
  /* border: 1px solid orange; */
  /* margin: 5% 0% 5% 0%; */
}

.workPlace {
  /* background-color: aqua; */
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;

  justify-content: flex-end;
}

.workPlaceClickable {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  transition: transform 0.5s;
  width: 100%;
  margin-bottom: 1.5%;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}
.workPlaceClickable:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.workPlaceClickableLeft {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  transition: transform 0.5s;
  width: 100%;
  margin-bottom: 1.5%;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}
.workPlaceClickableLeft:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.workPlaceFocusClickable {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  transition: transform 0.5s;
  width: 70%;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}
.workPlaceFocusClickable:hover {
  transform: translateZ(0);
  transform: scale(1.05);
  cursor: pointer;
}

.toolTipText {
  visibility: hidden;
  text-align: center;
  border-radius: 0.25em;
  white-space: nowrap;

  font-size: 0.5rem;

  margin-left: 15px;

  position: absolute;
  transition-property: visibility;
  transition-delay: 0s;
}
.workPlaceWrapper:hover .toolTipText {
  visibility: visible;
  transition-delay: 0.3s;
}
.workPlaceClickable:hover .toolTipText {
  visibility: visible;
  transition-delay: 0.5s;
  margin-bottom: 10%;
  margin-right: -10%;
}

.workPlaceFocus {
  /* background-color: aqua; */
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;

  justify-content: flex-start;
}

.jobHolder {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-right: 5px;
  /* border: 1px solid green; */
}
.jobHolderLeft {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-right: 5px;
  /* border: 1px solid green; */
}

.jobTitleLeftCard {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  margin-left: 5px;
}

.jobTitleRight {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-left: 5px;
  /* border: 1px solid green; */
}

.jobCompany {
  font-size: 1.25rem;
}
.workPlaceClickable:hover {
  /* backface-visibility: hidden; */
  /* filter: drop-shadow(0px 0px 10px rgba(160,160,160, .15)); */
}

.jobTitle {
  font-size: 0.75rem;
}

.jobYears {
  font-size: 0.5rem;
}

.iconHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  /* border: 1px solid blue; */
}

.iconSize {
  height: 75px;
  width: 75px;
}

.icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jobDescIcon {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: left;
}

.jobDescriptionContent {
  display: flex;
  justify-content: left;
  text-align: left;
  font-size: 0.75rem;
  width: 100%;
}
.jobDescriptionContentMobile {
  display: flex;
  justify-content: left;
  text-align: left;
  font-size: 0.75rem;
  width: 100%;
  /* margin-bottom: 30%; */
  font-size: .9rem;
}

.jobDescriptionContent ul {
  padding-left: 5%;
  list-style-type: none;
}
.jobDescriptionContent ul li {
  text-indent: -8px;
  margin-top: 2%;
}
.jobDescriptionContent ul li::before {
  content: "- ";
}
.jobDescriptionContentMobile ul {
  padding-left: 5%;
  list-style-type: none;
}
.jobDescriptionContentMobile ul li {
  text-indent: -8px;
  margin-top: 2%;
}
.jobDescriptionContentMobile ul li::before {
  content: "- ";
}

.jobBlurb {
  margin: 4% 1% 2%;
  text-align: left;
}
.jobBlurbMobile {
  margin: 4% 1% 2%;
  text-align: left;
  font-size: 1.1rem;
}

.jobLogo {
  width: 70%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788));
}
.jobLogo:hover {
  /* filter: drop-shadow(0px 0px 10px rgba(227, 241, 229, 0.396)); */
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.15));
}

.jobDescLogo {
  width: 30%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788));
}
.jobDescLogo:hover {
  /* filter: drop-shadow(0px 0px 10px rgba(227, 241, 229, 0.396)); */
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.15));
}

#NBCPeacockLogo {
  /* -webkit-filter: drop-shadow(2px 2px 2px #7c8f7f); */
  width: 92%;
  filter: drop-shadow(0px 0px 10px rgb(04, 18, 06));
}
#NBCPeacockLogo:hover {
  /* filter: drop-shadow(0px 0px 10px rgba(227, 241, 229, 0.396)); */
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.15));
}

#DepaulLogo {
  width: 70%;
}
#DepaulLogo:hover {
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.15));
}

#PeacockLogo {
  width: 70%;
}
#PeacockLogo:hover {
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.15));
}

#DoordashLogo {
  width: 85%;
}
#DoordashLogo:hover {
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.15));
}

#SnapchatLogo {
  width: 75%;
}
#SnapchatLogo:hover {
  filter: drop-shadow(0px 0px 10px rgba(160, 160, 160, 0.3));
}

#Communications {
  justify-content: flex-end;
}

.jobDescriptionCard {
  /* overflow: hidden; */
  height: 100%;
  align-items: flex-start;
  justify-content: right;
}

#projectPage {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  /* border: 1px solid yellow; */

  align-items: center;
  justify-content: flex-start;
  margin-top: 5%;
  margin-bottom: -5%;
  /*
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr;

  grid-template-areas:
    "none"
    "spacerGrid"; */
}

#padderBoi {
  height: 100px;
  display: flex;
  width: 100%;
}

#spacerBoi {
  /* grid-area: spacerGrid; */
  margin-top: 4%;

  width: 78%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  /* grid-template-columns: 1fr 3fr; */
  /* grid-template-rows: 3fr 1fr; */

  box-sizing: content-box;

  /* grid-template-areas:
    "menu demo"
    "menu details"; */

  /* border: 1px solid red; */
}
#spacerBoiMobile {
  /* grid-area: spacerGrid; */
  /* margin-top: 4%; */

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  /* grid-template-columns: 1fr 3fr; */
  /* grid-template-rows: 3fr 1fr; */

  box-sizing: content-box;

  /* grid-template-areas:
    "menu demo"
    "menu details"; */

  /* border: 1px solid red; */
}

.projectHeader {
  margin-bottom: 2%;
  font-size: 1.5rem;
  display: flex;
}

.projectDiv {
  height: 100%;
  /* width: 100%; */
  /* border: 1px solid #bdc0b9; */
  justify-self: center;
  flex-direction: column;
  display: flex; /*added*/
}

.dropDownWrapper {
  position: relative;
}

.dropDownMenu {
  /* background-color: rgb(04, 18, 06); */
  /* transition: 75ms ease-in; */
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
  /* overflow-y: scroll; */
}

.dropDownMenu[aria-hidden="false"] {
  grid-template-rows: 1fr;
}

.dropDownMenu > div {
  overflow: hidden;
}

.projectButton {
  background-color: rgba(4, 18, 6, 0);
  color: rgb(160, 160, 160);
  text-align: left;
  border: none;
  width: 100%;

  position: relative;

  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 1rem;
  font-variation-settings: "slnt" 0;
}

.projectButton:hover {
  /* background-color: rgba(4, 18, 6, 0); */
}

#projectUL {
  margin-bottom: 3%;
  margin-left: 2%;
}

#projectButtonText {
  width: auto;
}

#projectButtonText:not(.active):hover {
  text-decoration: underline;
  cursor: pointer;
}

#projectSpecButtonText {
  font-size: small;
  width: auto;
}

#projectSpecButtonText:hover {
  text-decoration: underline;
}

#projectMenu {
  text-align: start;
  grid-area: menu;
  overflow: hidden;
  margin-top: 1.25%;
}

#projectMenu ul li {
  list-style: none;
}

#projectDemo {
  grid-area: demo;
}

#selectProjectNotice {
  margin-top: 5%;
  display: flex;
  /* padding-top: 20%; */
  border: 1px solid rgb(160, 160, 160);
  min-height: 375px;
  align-items: center;
  justify-content: center;
  background-color: rgb(02, 15, 03);
  box-shadow: 0px 0px 10px rgb(0, 0, 0);

}
#selectProjectNoticeMobile {
  display: flex;
  /* padding-top: 20%; */
  border: 1px solid rgb(160, 160, 160);
  min-height: 225px;
  align-items: center;
  justify-content: center;
  background-color: rgb(02, 15, 03);
  box-shadow: 0px 0px 10px rgb(0, 0, 0);

}

.projectDescriptionCard {
  width: 67%;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788)); */
}
.projectDescriptionCardMobile {
  padding-top:4%;
  width: 100%;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788)); */
}

#VideoTmpMessage {
  display: flex;
  position: relative;
  padding-top: 24.125%;
  padding-bottom: 24.125%;
  justify-content: center;
  border: 1px solid rgb(160, 160, 160);
  background-color: rgb(02, 15, 03);
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
}

.player-wrapper {
  display: flex;

  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788));
}

.projectVideoCompVideoFlexWrapper {
  width: 100%;
  display: flex;
}

.projectVideoComp {
  display: flex;
  flex-direction: column;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788)); */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.788); */
}

.projectVideoCompVideo {
  width: 100%;
}

.projectVideoCompContent {
  width: 30%;
  border: 1px solid rgb(160, 160, 160);
  margin-left: 2%;
}

.projectVideoCompTitle {
  font-size: 1.25rem;
  text-align: left;
  margin-bottom: 0.25%;
}
.projectVideoCompSubTitle {
  font-size: 0.75rem;
  text-align: left;
  margin-bottom: 1%;
}
.projectVideoCompSummary {
  text-align: left;
}
.projectVideoCompSummaryMobile {
  text-align: left;
  font-size: 1.1rem;
}

#closeProject {
  width: 100%;
  display: flex;
  justify-content: end;
}

#closeProjectButton {
  cursor: default;
}

.projectVideoCompCaptionsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.75%;
  margin-bottom: 2%;
}
.projectVideoCompCaptions {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 500;
}

#projectVideoDetails {
  /* grid-area: details; */
  align-items: center;
  border: 1px solid rgb(160, 160, 160);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0.75%;
}

.projectVideoDescription {
  margin: 1%;
}

#mePage {
  width: 100%;
  height: 100%;

  display: grid;
  align-items: center;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
#mePageMobile {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* grid-template-columns: 1fr;
  grid-template-rows: 1fr; */
}

.meDiv {
  height: 80%;
  width: 78%;
  /* border: 1px solid #bdc0b9; */
  justify-self: center;
  margin-top: 1%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.meDivMobile {
  height: 80%;
  width: 78%;
  font-size: 1.1rem;
  /* border: 1px solid #bdc0b9; */
  justify-self: center;
  margin-top: 1%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

#meDivInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.backToTopContainer {
  width: 100%;
  height: 25px;
  position: fixed;
  bottom: 0;

  display: flex;
  align-items: center;
  position: fixed;
  background-color: rgba(255, 255, 255, 0);

  padding: 2px;
}

#backToTop {
  z-index: 1221;
  height: 100%;
  width: 50px;
}

#backToTopLabel {
  z-index: 1111;
  font-size: x-small;
  align-self: flex-end;
  /* padding-bottom: 10px;
  margin-left: -10px; */

  padding-bottom: 5px;
  margin-left: 5px;
}

#backToTopLabel:hover {
  cursor: default;
}

.meContent {
  padding-top: 1.5%;
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1.2fr 1fr 1fr;
  grid-template-areas:
    "woodworking metalworking  design     design  ST      ST"
    "woodworking climb       climb    music   animals   animals"
    "woodworking climb       climb    travel  animals   animals";
  grid-gap: 10px;
}
.meContentMobile {
  width: 100%;
  display: grid;
  margin: 8.5% 0% 0% 0%;
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  grid-template-areas:
    "woodworking" 
    "metalworking"  
    "design"
    "animals"
    "ST"  
    "music"
    "climb"
    "travel"
    ;
  /* grid-gap: 10px; */
}

#woodworking {
  grid-area: woodworking;
}
#metalworking {
  grid-area: metalworking;
}
#design {
  grid-area: design;
}
#animals {
  grid-area: animals;
}
#ST {
  grid-area: ST;
}
#music {
  grid-area: music;
}
#climb {
  grid-area: climb;
}
#travel {
  grid-area: travel;
}

.hobbyBox {
  border: solid 1px #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.788));
  transition: transform 0.5s;
  background-color: rgb(02, 15, 03);
  height: 100%;
}
.hobbyBox:hover {
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
  transform: scale(1.02);
}

.HobbyCard {
}

.HobbyCardTitle {
}

.HobbyHovered {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  transition: flex 1s;
  justify-content: center;
}

.HobbyHoveredTitle {
  text-align: center;
  width: 100%;
  /* padding: 5%; */
  /* padding-top: 3%; */
  padding-bottom: 1.5%;
}

.HobbyBlurb {
  width: 90%;
  font-size: 0.75rem;
  padding-bottom: 5%;
}

#HobbyExampleGridTitle {
  font-size: 0.75rem;
  align-self: center;
  padding: 5%;
}

.HobbyExamples {
  width: 85%;
  height: 100%;
  /* margin-bottom: 10%; */
}

.HobbyExampleGridContainer {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.HobbyExampleGridWrapper {
  /* border: solid 1px #707070; */
  height: 75%;
  width: 100%;
}

.HobbyExampleGrid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0px;
}
.HobbyExampleGrid_1x2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5%;
}
.HobbyExampleGrid_3x3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0px;
  grid-gap: 5%;
}

.HobbyExampleGridCell {
  /* border: solid 1px #707070; */
  
  width: 100%;
  aspect-ratio: 1;
  width: 100%;
}
.HobbyExampleGridCell:hover {
  scale: 1.02;
}

.HobbyExampleGridCellImage {
  width: 100%;
  height: 100%;
}

.HobbyPhoto {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 0 0;
}

#contactPage {
  /* background-image: var(--mainBottomGradient); */
  width: 100%;

  display: flex;
  /* padding-top: 6%; */
  z-index: 2;
  justify-content: space-between;
}
#contactPageMobile {
  /* background-image: var(--mainBottomGradient); */
  width: 100%;

  display: flex;
  /* padding-top: 6%; */
  z-index: 2;
  justify-content: space-between;
}

#justFuckMyShitUpJerry {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  z-index: -100;
}

.contactPageContent {
  z-index: 5;
  width: 82%;
  height: 100%;
  display: grid;
  align-items: center;

  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  grid-template-areas: "form formSupport";

  margin-top: 5%;
}
.contactPageContentMobile {
  z-index: 5;
  width: 100%;
  height: 100%;
  /* display: grid; */
  align-items: center;

  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  grid-template-areas: "form formSupport";

  margin-top: 45%;
}

.formSide {
  grid-area: form;
  height: 100%;
  width: 100%;
  align-content: flex-start;
  padding: 5%;
}

.formSupportSide {
  grid-area: formSupport;
  height: 100%;
  width: 100%;
  align-content: center;
  padding: 5%;
  padding-bottom: 0%;
}

.formSupportSideContent {
  height: 100%;
  width: 100%;
  align-content: center;
  /* border: solid #707070; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 5%;
}

.emailForm {
  /* height: 50%; */
  width: 100%;
  /* background-color: #041206; */
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
}

.formHeader {
  margin-bottom: 4%;
  font-size: 1.25rem;
}

.formOneLiner {
  height: 20%;
  color: #707070;
  background-color: #04120600;
  border-style: none;
  border-bottom: solid 1px #707070;
  /* margin: 2%; */
  margin-top: 1%;
  padding: 1.5%;
}
.formOneLiner:focus {
  outline-width: 0;
}

#formTextArea {
  height: 125px;
  max-width: 700px;
  min-width: 90px;
  max-height: 300px;
  width: 100%;
  color: #707070;
  background-color: #04120600;
  border-style: none;
  border: solid 1px #707070;
  margin-top: 3%;
  padding: 1.5%;
}
#formTextArea:focus {
  outline-width: 0;
}

.submitButton {
  width: 50%;
  align-self: center;
  background-color: #04120600;
  color: #707070;
  border: solid 1px #707070;
  margin-top: 3%;
  padding: 2%;
  transition: transform 0.1s;

}

.submitButton:hover{
  background-color: #0D180F;
  transform: scale(1.02);
}

.contactInfoPaddyWagon {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  /* padding-left: 10%; */
  margin-top: 12%;
}

.welcomeMessage {
  display: flex;
  justify-content: right;
  padding-top: 1%;
}

.socialLinks {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 3%;
  z-index: 6;
}
.socialLinksMobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 3%;
  z-index: 6;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badgeLink {
  width: 75px;
  height: 100%;
}

.badgeLinkWrapper {
  width: 100%;
  height: 100%;
}

a {
  color: rgb(160, 160, 160);
}

a:visited {
  text-decoration: none;
  color: rgb(160, 160, 160);
}

.badgeIcon {
  /* width: 100px; */
}

#generalContactInfo {
  height: 50%;
  width: 100%;
}

.contactInfoItem {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 5%;
  font-size: 1.1rem;
}

.contactInfoItemInfo {
  padding-left: 5%;
  position: relative;
  display: inline-block;
  cursor: default;
}

.toolTipText {
  visibility: hidden;
  text-align: center;
  border-radius: 0.25em;
  white-space: nowrap;

  font-size: 0.5rem;

  margin-left: 15px;

  position: absolute;
  transition-property: visibility;
  transition-delay: 0s;
}
.contactInfoItemInfo:hover .toolTipText {
  visibility: visible;
  transition-delay: 0.3s;
}

#emailCopy:hover {
}

.contactPageR3F {
  /* width: 90%;
  height: 70%; */
  z-index: 1000000;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

#contactPageCanvas {
  padding-right: 10%;
  /* padding-top: 50%; */
  /* margin-top: 25%;
  width: 27%;
  height: 60%; */
}

.toolTipTextMeImage {
  visibility: hidden;
  text-align: center;
  white-space: nowrap;
  font-size: 0.5rem;
  /*
  display: flex;
  margin-left: 105%;
  margin-top: -6%;

  transition-property: visibility;
  transition-delay: 0s;
  position: absolute;
  */
}
.HobbyExampleGridCellContent:hover .toolTipTextMeImage {
  visibility: visible;
  transition-delay: 0.3s;
  /* border: solid 1px rgb(160, 160, 160); */
}

#mobileOops {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  /* margin-top: 50%; */
  /* padding: 5%; */
  width:100%;
  font-size: 1.5rem;
}

.mobileMessage {
  margin-bottom: 2%;
}

#mobileMessageSubHeader {
  font-size: 1.25rem;
}
